/* EmojiAnimator.css */
.emoji-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.emoji-animation-container {
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animate-emoji {
  opacity: 1;
  transform: translateY(-20px);
}

.emoji {
  font-size: 3rem;
}

/* Add animation delays for staggered animations */
.delay-1 {
  animation-delay: 0.2s;
}

.delay-2 {
  animation-delay: 0.4s;
}

.delay-3 {
  animation-delay: 0.6s;
}

/* Add more delays as needed */
