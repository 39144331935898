.warning {
    display: flex;
    align-items: center;
    font-size: 20px; /* Adjust the font size as needed */
    background-color: red; /* Warning background color */
    color: #000000; /* Text color */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    color:white;
}

.warning-text {
    margin-left: 10px;
    animation: flash 1s infinite; /* Apply the flashing animation */
}

/* Keyframes for the flashing effect */
@keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}
